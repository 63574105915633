import styled from "styled-components";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Box from '@mui/material/Box';
import {Typography} from "@mui/material";
import {useSelector} from "react-redux";

import PaymentForm from "../components/PaymentForm";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useEffect, useState} from "react";

const stripePromise = loadStripe('pk_test_51Odv2ULgRo1w7m00IXF8GeYHcx1QXkbsQp76ikuSywtC5uSU70OYuexHcHEqXbJoPdJMLTLI0eLbdvcRBgEU3nt700AXKIqp3y');

const Container = styled.div`
  min-height: calc(100% - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  background-color: #171717;
  box-shadow: 0px 0px 15px 2px #0c0c0c;
  border-radius: 10px;
`;

function Payment() {

    const [clientSecret, setClientSecret] = useState("");

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch("http://localhost:3032/create-payment-intent", {
            method: "GET",
            headers: {"Content-Type": "application/json"},
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
    }, []);

    const appearance = {
        theme: 'night',
    };
    const options = {
        clientSecret,
        appearance,
    };

    const navigate = useNavigate();

    const {user: currentUser} = useSelector((state) => state.auth);

    return (
        <Container>
            <Wrapper>
                <Box px={3} py={2}>
                    <Typography variant="h4" align="center" margin="dense">
                        Paiement
                    </Typography>
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <PaymentForm/>
                        </Elements>
                    )}
                </Box>
            </Wrapper>
        </Container>
    );
}

export default Payment;