import axios from "axios";

//const API_URL = "http://localhost:3032/auth/";
const API_URL = "/auth/";

const register = (name, firstName, email, password) => {
    return axios.post(API_URL + "signup", {
        name,
        firstName,
        email,
        password,
    });
};

const login = (email, password) => {
    return axios
        .post(API_URL + "signin", {
            email,
            password,
        })
        .then((response) => {
            if (response.data.accessToken) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

export default {
    register,
    login,
    logout,
};