import styled from "styled-components";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  min-height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5%;
  color: #EAEDED;
`;

function Footer() {

    const { t } = useTranslation();

    const navigate = useNavigate();

    return (
        <Container>
            <p>Copyright © 2024 MYPROTTRACKER. {t('copyright.rights')} {t('copyright.terms')}</p>
        </Container>
    );
}

export default Footer;